/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
@tailwind preflight;
/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
@tailwind components;
/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */
.bullets-small > li, .bullets-large > li {
  @apply w-full flex px-6; }
  .bullets-small > li .checkmark, .bullets-large > li .checkmark {
    @apply rounded-full flex-no-shrink text-center flex items-center justify-center; }
  .bullets-small > li > a, .bullets-large > li > a {
    @apply flex; }

.bullets-small > li {
  @apply py-1; }
  .bullets-small > li .checkmark {
    @apply w-6 h-6 mr-2 text-base mt-1; }
  .bullets-small > li .bullet-text {
    @apply leading-loose font-medium; }

.bullets-large > li {
  @apply py-4; }

@screen xl {
  .bullets-large > li {
    @apply w-1/2 py-8; } }
  .bullets-large > li .checkmark {
    @apply w-12 h-12 mr-4 leading-loose text-xl font-bold mt-1; }

@apply md {
  .bullets-large > li .checkmark {
    @apply w-16 h-16 text-2xl -mt-1; } }
  .bullets-large > li .bullet-text {
    @apply text-2xl leading-loose; }

.bullets-grey .checkmark {
  @apply border-2 border-grey text-grey-lightest; }

.bullets-black .checkmark {
  @apply border border-black text-black; }

.bullets-black a:hover .checkmark {
  @apply border border-orange text-orange; }

.bullets-orange .checkmark {
  @apply border-none bg-orange text-grey-lightest; }

.btn-orange-gradient-outline, .btn-blue-outline, .btn-grey-outline, .btn-white, .btn-orange, .btn-grey, .btn-blue-gradient {
  @apply inline py-4 px-8 font-medium tracking-widest uppercase leading-normal;
  background-origin: border-box; }

.btn-blue-underline, .btn-white-underline, .btn-orange-underline {
  @apply inline pt-1 pb-1 font-medium text-lg border-b; }

@variants hover {
  .btn-orange-gradient-outline {
    @apply bg-transparent border-3 border-gradient-br-orange text-grey-lighter;
    @apply py-3 px-4 tracking-wide; }
  .btn-blue-outline {
    @apply bg-transparent border-3 border-gradient-blue text-grey-lighter; }
  .btn-grey-outline {
    @apply bg-transparent border-2 border-grey text-grey-darker; }
  .btn-white {
    @apply bg-white border-3 border-transparent border-gradient-t-transparent text-blue; }
  .btn-orange {
    @apply bg-orange border-2 border-transparent border-gradient-t-transparent text-grey-lightest; }
  .btn-grey {
    @apply bg-grey-darker border-2 border-transparent border-gradient-t-transparent text-grey-lightest; }
  .btn-blue-gradient {
    @apply bg-gradient-330-blue border-3 border-transparent border-gradient-t-transparent text-grey-lightest; }
  .btn-blue-underline {
    @apply text-blue border-blue; }
  .btn-white-underline {
    @apply text-grey-lightest border-grey-lightest; }
  .btn-orange-underline {
    @apply text-orange border-orange; } }

.btn-facebook, .btn-twitter, .btn-linkedin, .btn-dribbble, .btn-youtube {
  @apply inline-block mr-4 w-16 h-16 border-2 rounded-full; }
  .btn-facebook:last-child, .btn-twitter:last-child, .btn-linkedin:last-child, .btn-dribbble:last-child, .btn-youtube:last-child {
    @apply mr-0; }
  .btn-facebook > a, .btn-twitter > a, .btn-linkedin > a, .btn-dribbble > a, .btn-youtube > a {
    @apply h-full w-full flex justify-center items-center; }
    .btn-facebook > a > svg, .btn-twitter > a > svg, .btn-linkedin > a > svg, .btn-dribbble > a > svg, .btn-youtube > a > svg {
      @apply fill-current w-8 h-8; }
  .blogPostSection .btn-facebook, .blogPostSection .btn-twitter, .blogPostSection .btn-linkedin, .blogPostSection .btn-dribbble, .blogPostSection .btn-youtube {
    @apply w-12 h-12; }
    .blogPostSection .btn-facebook > a > svg, .blogPostSection .btn-twitter > a > svg, .blogPostSection .btn-linkedin > a > svg, .blogPostSection .btn-dribbble > a > svg, .blogPostSection .btn-youtube > a > svg {
      @apply w-5 h-5; }
  footer .btn-facebook, footer .btn-twitter, footer .btn-linkedin, footer .btn-dribbble, footer .btn-youtube {
    @apply w-12 h-12;
    @apply opacity-50 border-0 bg-blue-darkest; }
    footer .btn-facebook > a, footer .btn-twitter > a, footer .btn-linkedin > a, footer .btn-dribbble > a, footer .btn-youtube > a {
      @apply text-white; }
      footer .btn-facebook > a > svg, footer .btn-twitter > a > svg, footer .btn-linkedin > a > svg, footer .btn-dribbble > a > svg, footer .btn-youtube > a > svg {
        @apply w-5 h-5; }
    footer .btn-facebook:hover, footer .btn-twitter:hover, footer .btn-linkedin:hover, footer .btn-dribbble:hover, footer .btn-youtube:hover {
      @apply bg-white; }
      footer .btn-facebook:hover > a, footer .btn-twitter:hover > a, footer .btn-linkedin:hover > a, footer .btn-dribbble:hover > a, footer .btn-youtube:hover > a {
        @apply text-blue-darker; }

.btn-facebook {
  @apply border-facebook; }
  .btn-facebook > a {
    @apply text-facebook; }
  .btn-facebook:hover {
    @apply bg-facebook; }
    .btn-facebook:hover > a {
      @apply text-white; }

.btn-twitter {
  @apply border-twitter; }
  .btn-twitter > a {
    @apply text-twitter; }
  .btn-twitter:hover {
    @apply bg-twitter; }
    .btn-twitter:hover > a {
      @apply text-white; }

.btn-linkedin {
  @apply border-linkedin; }
  .btn-linkedin > a {
    @apply text-linkedin; }
  .btn-linkedin:hover {
    @apply bg-linkedin; }
    .btn-linkedin:hover > a {
      @apply text-white; }

.btn-dribbble {
  @apply border-dribbble; }
  .btn-dribbble > a {
    @apply text-dribbble; }
  .btn-dribbble:hover {
    @apply bg-dribbble; }
    .btn-dribbble:hover > a {
      @apply text-white; }

.btn-youtube {
  @apply border-youtube; }
  .btn-youtube > a {
    @apply text-youtube; }
  .btn-youtube:hover {
    @apply bg-youtube; }
    .btn-youtube:hover > a {
      @apply text-white; }

.grid {
  @apply flex flex-wrap -mx-4; }
  .grid .grid-item {
    @apply p-4; }

@font-face {
  font-family: 'icomoon';
  src: url("/fonts/icomoon.ttf") format("truetype");
  @apply roman font-normal; }

[class*="icon-"]:before, .icon-down:before, .select2 .select2-selection__arrow:before, .icon-checkmark:before, input.labelauty + label .labelauty-checked-image:before, a.comment-action[data-action="reply"]:before, a.comment-action[data-action="edit"]:before, a.comment-action[data-action="delete"]:before, a.comment-action.icon-close:before, .bullet:before {
  font-family: 'icomoon';
  speak: none;
  font-weight: normal;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  @apply roman pr-1; }

@responsive {
  .icon-large {
    font-size: 1.3em;
    vertical-align: sub; }
  .icon-small {
    font-size: 0.8em; } }

.icon-comments:before {
  content: "\eb8c"; }

.icon-watch:before {
  content: "\ea2a"; }

.icon-tags:before {
  content: "\eb2f"; }

.icon-search:before {
  content: "\eadf"; }

.icon-warning:before {
  content: "\e904"; }

.icon-volume:before {
  content: "\eb17"; }

.icon-down:before, .select2 .select2-selection__arrow:before {
  content: "\e916"; }

.icon-checkmark:before, input.labelauty + label .labelauty-checked-image:before {
  content: "\e9b9"; }

.icon-external-link:before {
  @apply inline-block;
  content: "\e920";
  transform: rotate(45deg); }

a.comment-action[data-action="reply"]:before {
  content: "\e909"; }

a.comment-action[data-action="edit"]:before {
  content: "\eaac"; }

a.comment-action[data-action="delete"]:before {
  content: "\eb43"; }

a.comment-action.icon-close:before {
  content: "\e980" !important;
  font-size: 0.9em; }

.bullet:before {
  content: '\25d8';
  vertical-align: super; }

.contactForm:not(:first-child) {
  @apply mt-8; }

[data-role="reply"] .form-items {
  @apply pt-6; }

.form-item textarea {
  @apply resize-none min-h-50; }

.form-item .input-horizontal, .form-item .input-horizontal-lg, .form-item .input-vertical, .form-item .input-normal, .form-item .input-search {
  @apply relative w-full; }
  .form-item .input-horizontal > input, .form-item .input-horizontal-lg > input, .form-item .input-vertical > input, .form-item .input-normal > input, .form-item .input-search > input,
  .form-item .input-horizontal > textarea,
  .form-item .input-horizontal-lg > textarea,
  .form-item .input-vertical > textarea,
  .form-item .input-normal > textarea,
  .form-item .input-search > textarea {
    @apply border-2 border-grey p-4 w-full outline-none text-grey-darkest leading-normal; }
    .form-item .input-horizontal > input:focus, .form-item .input-horizontal-lg > input:focus, .form-item .input-vertical > input:focus, .form-item .input-normal > input:focus, .form-item .input-search > input:focus,
    .form-item .input-horizontal > textarea:focus,
    .form-item .input-horizontal-lg > textarea:focus,
    .form-item .input-vertical > textarea:focus,
    .form-item .input-normal > textarea:focus,
    .form-item .input-search > textarea:focus {
      @apply border-orange-lighter; }
  .form-item .input-horizontal > label, .form-item .input-horizontal-lg > label, .form-item .input-vertical > label, .form-item .input-normal > label, .form-item .input-search > label {
    @apply absolute pin-t pin-x font-bold leading-none border-2 border-transparent py-4 px-4 w-full bg-white leading-normal;
    background-clip: padding-box; }

@responsive {
  .form-item .input-horizontal > input,
  .form-item .input-horizontal > textarea {
    @apply pl-24 pt-4; }
  .form-item .input-horizontal > label {
    @apply w-24; }
  .form-item .input-horizontal-lg > input,
  .form-item .input-horizontal-lg > textarea {
    @apply pl-40 pt-4; }
  .form-item .input-horizontal-lg > label {
    @apply w-40; }
  .form-item .input-vertical > input,
  .form-item .input-vertical > textarea {
    @apply pl-4 pt-16; }
  .form-item .input-vertical > label {
    @apply w-full; }
  .form-item .input-normal > input,
  .form-item .input-normal > textarea {
    @apply w-full; }
  .form-item .input-normal > label {
    @apply w-full inline-block relative pl-0 pt-0 pr-2; }
  .form-item .input-search > button {
    @apply absolute pin-y pin-r border-2 border-transparent py-3 px-4 bg-white;
    background-clip: padding-box; }
    .form-item .input-search > button:hover {
      @apply text-orange transition-text; }
    .form-item .input-search > button:focus {
      @apply outline-none bg-orange text-white; } }

.select2 {
  @apply w-full !important; }
  .select2 .select2-selection {
    @apply border-2 border-grey rounded-none h-auto outline-none !important; }
    .select2 .select2-selection:focus {
      @apply border-orange-lighter !important; }
    .select2 .select2-selection__rendered {
      @apply p-4 !important; }
  .select2 .select2-selection__arrow {
    @apply p-6 w-16 h-16 bg-grey-lighter border-2 border-transparent pin-t pin-r !important;
    background-clip: padding-box;
    -webkit-text-stroke: 3px config("colors.grey-darker"); }
    .select2 .select2-selection__arrow b {
      @apply hidden !important; }

.select2-dropdown {
  @apply border-b-2 border-r-2 border-l-2 border-grey rounded-none; }
  .select2-dropdown .select2-results__option {
    @apply p-4 !important; }
    .select2-dropdown .select2-results__option[aria-selected=true] {
      @apply bg-grey-light text-black !important; }
      .select2-dropdown .select2-results__option[aria-selected=true]:hover {
        @apply text-black !important; }
    .select2-dropdown .select2-results__option--highlighted {
      @apply bg-orange !important; }

input.labelauty + label {
  @apply relative py-4 px-5 rounded-full bg-grey-lighter mb-3 !important;
  @apply w-5/6  !important; }

@screen sm {
  input.labelauty + label {
    @apply w-auto mr-5 !important; } }
  input.labelauty + label .labelauty-unchecked,
  input.labelauty + label .labelauty-checked {
    @apply text-base font-normal !important; }
  input.labelauty + label .labelauty-unchecked-image {
    @apply hidden !important;
    background-image: none !important; }
  input.labelauty + label .labelauty-unchecked {
    @apply text-orange !important; }
  input.labelauty + label .labelauty-checked-image {
    background-image: none !important;
    @apply absolute pin-y my-auto bg-white text-grey-darker rounded-full w-5 h-5 p-1 text-2xs border border-orange !important;
    right: -0.5rem;
    -webkit-text-stroke: 2px config("colors.grey-darker"); }

input.labelauty:checked + label {
  @apply bg-orange !important; }

.newsletterSignupForm input {
  @apply border-gradient-blue-light border-3 bg-transparent text-white !important; }
  .newsletterSignupForm input::placeholder {
    @apply opacity-75; }

.form-item .has-error > input,
.form-item .has-error > textarea,
.form-item .has-error .select2-selection {
  @apply border-red; }

.ls-blur-up-img,
.optimized-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  /* only if you want to change the blur-up option from auto to always */
  font-family: "blur-up: always", "object-fit: cover";
  object-fit: cover; }

.ls-blur-up-img {
  filter: blur(10px);
  opacity: 1;
  transition: opacity 1000ms, filter 1500ms; }

.ls-blur-up-img.ls-inview.ls-original-loaded {
  opacity: 0;
  filter: blur(5px); }

.filter, .paginate-link {
  @apply py-2 px-3 m-1; }

@screen md {
  .filter, .paginate-link {
    @apply py-4 px-5; } }

.filter {
  @apply font-bold; }

.filter.selected,
.paginate-link.selected {
  @apply bg-orange text-white; }

.filter.not-selected,
.paginate-link.not-selected {
  @apply bg-grey-lighter text-orange no-underline; }

.navbars {
  @apply fixed w-full z-10; }

.navbar {
  @apply w-full p-12 flex flex-wrap justify-between items-center relative;
  @apply transition-padding-and-bg transition-duration-200; }

.navbar-scrolled {
  @apply bg-blue-darkest py-4;
  @apply transition-padding-and-bg transition-duration-200; }

#navbar.opened:not(.navbar-scrolled) > .background {
  @apply opacity-50; }

.nav-item {
  @apply text-sm font-bold text-grey-lighter tracking-wide no-underline transition-text transition-duration-200; }
  .nav-item.active {
    @apply text-orange border-b-2 border-orange; }
  .nav-item:hover {
    @apply text-orange; }

@screen lg {
  .nav-item {
    @apply tracking-wider; } }

@screen xl {
  .nav-item {
    @apply text-base; } }

.navbar-toggle {
  @apply flex items-center px-3 py-2 border-3 border-gradient-br-orange text-white; }
  .navbar-toggle:focus {
    @apply border-gradient-br-white outline-none; }

.mobile-navbar {
  @apply w-full transition-opacity-and-max-height transition-duration-500 relative overflow-hidden; }
  .mobile-navbar.opened {
    @apply opacity-100 max-h-screen; }
  .mobile-navbar #mobile-navbar-button > a {
    @apply my-0 btn-blue-outline w-full text-center;
    border-image: none; }

/* Animate menu icon (lines). */
.navbar-toggle-group .line {
  opacity: 1;
  transform: rotate(0) translateY(0) translateX(0);
  transform-origin: 1em 1em;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out; }

.navbar-toggle-group .line-1 {
  transform-origin: 1em 2.5em; }

.navbar-toggle-group .line-3 {
  transform-origin: 1em 4.5em; }

#navbar.opened .navbar-toggle-group .line-1 {
  transform: rotate(45deg) translateY(0) translateX(0); }

#navbar.opened .navbar-toggle-group .line-2 {
  opacity: 0; }

#navbar.opened .navbar-toggle-group .line-3 {
  transform: rotate(-45deg) translateY(0em) translateX(0em); }

.row {
  @apply flex flex-wrap justify-center relative; }

.container, .container-wide {
  @apply w-full mx-auto; }

@screen sm {
  .container, .container-wide {
    max-width: 576px; } }

@screen md {
  .container, .container-wide {
    max-width: 768px; } }

@screen lg {
  .container, .container-wide {
    max-width: 992px; } }

@screen xl {
  .container, .container-wide {
    max-width: 1200px; } }

@screen sm {
  .container-wide {
    @apply max-w-full; } }

@screen xxl {
  .container-wide {
    max-width: 1600px; } }

.section {
  @apply py-20 px-8 relative; }

@screen lg {
  .section {
    @apply px-32; } }

.section-content {
  @apply min-w-full flex flex-wrap; }

.section-content > .heading,
.section-content > .richText,
.section-content > .button,
.section-content > .bulletPoints {
  @apply px-8; }

.imageSection {
  @apply min-h-100 px-0; }

@screen max-sm {
  .imageSection {
    @apply -mt-20 mb-20 py-12; }
    .subSection ~ .imageSection {
      @apply -mb-20 mt-20; }
    .imageSection .triangle-tr-white {
      border-width: 0 0 100px 100px;
      border-color: transparent transparent white transparent;
      top: auto !important;
      bottom: 0; } }

@screen md {
  .imageSection {
    @apply pin-l my-0; }
    .subSection ~ .imageSection {
      @apply pin-r my-0;
      left: auto; } }

@screen max-sm {
  .subSection + .subSection {
    @apply pt-12; } }

.serviceOrder div:nth-child(1) {
  order: 1; }

.serviceOrder div:nth-child(2) {
  order: 2; }

.serviceOrder div:nth-child(3) {
  order: 3; }

@screen md {
  .serviceOrder div:nth-child(3) {
    order: 4; } }

.serviceOrder div:nth-child(4) {
  order: 4; }

@screen md {
  .serviceOrder div:nth-child(4) {
    order: 3; } }

.serviceOrder div:nth-child(5) {
  order: 5; }

.serviceOrder div:nth-child(6) {
  order: 6; }

.serviceOrder .triangle-tl-darkBlue,
.serviceOrder .triangle-tr-darkBlue {
  @apply hidden; }

@screen md {
  .serviceOrder .triangle-tl-darkBlue,
  .serviceOrder .triangle-tr-darkBlue {
    @apply block; } }

.serviceOrder div:last-child .triangle-bl-darkBlue,
.serviceOrder div:last-child .triangle-br-darkBlue,
.serviceOrder div:nth-last-child(2) .triangle-bl-darkBlue,
.serviceOrder div:nth-last-child(2) .triangle-br-darkBlue {
  @apply block; }

@screen md {
  .serviceOrder div:last-child .triangle-bl-darkBlue,
  .serviceOrder div:last-child .triangle-br-darkBlue,
  .serviceOrder div:nth-last-child(2) .triangle-bl-darkBlue,
  .serviceOrder div:nth-last-child(2) .triangle-br-darkBlue {
    @apply hidden; } }

.testimonialSection > .absolute[class*="bg-"]:nth-child(2) {
  width: 33.33333%;
  left: auto; }

@screen max-sm {
  .testimonialSection > .absolute[class*="bg-"]:nth-child(2) {
    @apply h-80;
    top: auto; } }

.slick-dots {
  @apply absolute pin-b pin-x mx-auto list-reset flex justify-center mb-16; }

@screen max-sm {
  .slick-dots {
    margin-bottom: 21rem; } }
  .slick-dots > li {
    font-size: 0;
    line-height: 0;
    @apply border-2 border-white w-4 h-4 rounded-full mx-1; }
    .slick-dots > li.slick-active {
      @apply bg-orange border-transparent; }

@screen md {
  .slick-track {
    @apply flex; }
    .slick-track .testimonial {
      @apply flex w-full h-auto justify-center items-center; } }

body {
  @apply font-sans text-black; }

.faux-h1, .faux-h1-alt, .faux-h2, .faux-h2-alt, .faux-h3, .faux-h3-alt, .faux-h4, .faux-h4-alt, .faux-h5, .faux-h5-alt, .faux-h6, .faux-h6-alt {
  @apply block; }
  .faux-h1 > em, .faux-h1-alt > em, .faux-h2 > em, .faux-h2-alt > em, .faux-h3 > em, .faux-h3-alt > em, .faux-h4 > em, .faux-h4-alt > em, .faux-h5 > em, .faux-h5-alt > em, .faux-h6 > em, .faux-h6-alt > em {
    @apply roman; }

.faux-h1 {
  @apply uppercase text-5xl font-bold; }

@screen md {
  .faux-h1 {
    @apply text-6xl; } }

.faux-h1-alt {
  @apply uppercase text-4xl font-normal leading-none pt-1 pb-4 font-sans-condensed; }

@screen md {
  .faux-h1-alt {
    @apply text-5xl; } }
  .faux-h1-alt.border-skewed-orange {
    @apply max-w-sm; }
    .faux-h1-alt.border-skewed-orange.text-center {
      @apply mx-auto; }
  .faux-h1-alt.md\:text-xxl {
    @apply max-w-xl; }
    .faux-h1-alt.md\:text-xxl.text-center {
      @apply mx-auto; }

.faux-h2, .faux-h2-alt {
  @apply normal-case text-4xl font-normal leading-tight pt-1 pb-4; }

@screen md {
  .faux-h2, .faux-h2-alt {
    @apply text-5xl; } }

.faux-h2-alt {
  @apply font-bold; }

.faux-h3, .faux-h3-alt {
  @apply normal-case text-3xl font-normal leading-normal pt-1 pb-2; }

@screen md {
  .faux-h3, .faux-h3-alt {
    @apply text-4xl; } }

.faux-h3-alt {
  @apply uppercase tracking-wider; }

.faux-h4, .faux-h4-alt {
  @apply normal-case text-xl font-normal leading-normal pt-1 pb-2; }

@screen md {
  .faux-h4, .faux-h4-alt {
    @apply text-2xl; } }

.faux-h4-alt {
  @apply uppercase tracking-wider; }

.faux-h5, .faux-h5-alt {
  @apply uppercase text-xl font-medium tracking-wide; }

.faux-h5-alt {
  @apply font-bold; }

.faux-h6, .faux-h6-alt {
  @apply uppercase text-base font-medium tracking-wide; }

.faux-h6-alt {
  @apply font-bold; }

.standard-p {
  @apply text-base leading-loose my-4; }
  .standard-p:first-child {
    @apply mt-0; }
  .blogPostSection .section-content .richText:first-child > .standard-p {
    @apply text-lg; }
  .blogPostSection .section-content .standard-p {
    @apply leading-relaxed; }
  .standard-p a {
    @apply text-orange; }

.heroSection .standard-p {
  @apply max-w-md; }

small {
  @apply text-lg; }

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;
/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-20px); }
  60% {
    transform: translateY(-10px); } }

.animation-bounce {
  animation: bounce 2s infinite ease; }

.triangle-tr-white {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-t pin-r;
  border-width: 0 100px 100px 0;
  border-color: transparent white transparent transparent; }

@screen md {
  .triangle-tr-white {
    border-width: 0 200px 200px 0; } }

.triangle-br-white {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-b pin-r;
  border-width: 0 0 100px 100px;
  border-color: transparent transparent white transparent; }

@screen md {
  .triangle-br-white {
    border-width: 0 0 200px 200px; } }

.triangle-bl-white {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-b pin-l;
  border-width: 100px 0 0 100px;
  border-color: transparent transparent transparent white; }

@screen md {
  .triangle-bl-white {
    border-width: 200px 0 0 200px; } }

.triangle-tl-white {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-t pin-l;
  border-width: 100px 100px 0 0;
  border-color: white transparent transparent transparent; }

@screen md {
  .triangle-tl-white {
    border-width: 200px 200px 0 0; } }

.triangle-tr-darkBlue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-t pin-r;
  border-width: 0 100px 100px 0;
  border-color: transparent #163348 transparent transparent; }

@screen md {
  .triangle-tr-darkBlue {
    border-width: 0 200px 200px 0; } }

.triangle-br-darkBlue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-b pin-r;
  border-width: 0 0 100px 100px;
  border-color: transparent transparent #163348 transparent; }

@screen md {
  .triangle-br-darkBlue {
    border-width: 0 0 200px 200px; } }

.triangle-bl-darkBlue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-b pin-l;
  border-width: 100px 0 0 100px;
  border-color: transparent transparent transparent #163348; }

@screen md {
  .triangle-bl-darkBlue {
    border-width: 200px 0 0 200px; } }

.triangle-tl-darkBlue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-t pin-l;
  border-width: 100px 100px 0 0;
  border-color: #163348 transparent transparent transparent; }

@screen md {
  .triangle-tl-darkBlue {
    border-width: 200px 200px 0 0; } }

.triangle-tr-blue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-t pin-r;
  border-width: 0 100px 100px 0;
  border-color: transparent #218aae transparent transparent; }

@screen md {
  .triangle-tr-blue {
    border-width: 0 200px 200px 0; } }

.triangle-br-blue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-b pin-r;
  border-width: 0 0 100px 100px;
  border-color: transparent transparent #218aae transparent; }

@screen md {
  .triangle-br-blue {
    border-width: 0 0 200px 200px; } }

.triangle-bl-blue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-b pin-l;
  border-width: 100px 0 0 100px;
  border-color: transparent transparent transparent #218aae; }

@screen md {
  .triangle-bl-blue {
    border-width: 200px 0 0 200px; } }

.triangle-tl-blue {
  @apply absolute w-0 h-0 border-solid;
  @apply pin-t pin-l;
  border-width: 100px 100px 0 0;
  border-color: #218aae transparent transparent transparent; }

@screen md {
  .triangle-tl-blue {
    border-width: 200px 200px 0 0; } }

.concave-pentagon {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 65%, 0% 100%); }

.border-skewed-grey, .border-skewed-orange {
  @apply relative pb-6 leading-none; }
  .border-skewed-grey:after, .border-skewed-orange:after {
    @apply absolute w-16 pin-b pin-l;
    content: "";
    height: 5px;
    transform: skewX(45deg); }
  .text-center.border-skewed-grey:after, .text-center.border-skewed-orange:after {
    @apply pin-x mx-auto; }

.border-skewed-grey:after {
  @apply bg-grey; }

.border-skewed-orange:after {
  @apply bg-orange; }

.border-skewed-orange.text-xxl:after {
  @apply w-24 h-2; }

@responsive {
  .order-first {
    order: -99999; }
  .order-last {
    order: 99999; }
  .order-initial {
    order: 0; } }
