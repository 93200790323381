@font-face {
	font-family: 'icomoon';
	src: url('/fonts/icomoon.ttf') format('truetype');
	@apply roman font-normal;
}

%icon:before {
	font-family: 'icomoon';
	speak: none;
	font-weight: normal;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	@apply roman pr-1;
}

[class*="icon-"] {
	@extend %icon;
}

@responsive {
	.icon-large {
		font-size: 1.3em;
		vertical-align: sub;
	}

	.icon-small {
		font-size: 0.8em;
	}
}

.icon-comments:before {
	content: "\eb8c";
}

.icon-watch:before {
	content: "\ea2a";
}

.icon-tags:before {
	content: "\eb2f";
}

.icon-search:before {
	content: "\eadf";
}

.icon-warning:before {
	content: "\e904";
}

.icon-volume:before {
	content: "\eb17";
}

.icon-down:before {
	@extend %icon;
	content: "\e916";
}

.icon-checkmark:before {
	@extend %icon;
	content: "\e9b9";
}

.icon-external-link:before {
	@apply inline-block;
	content: "\e920";
	transform: rotate(45deg);
}



a.comment-action[data-action="reply"] {
	@extend %icon;

	&:before {
		content: "\e909";
	}
}

a.comment-action[data-action="edit"] {
	@extend %icon;

	&:before {
		content: "\eaac";
	}
}

a.comment-action[data-action="delete"] {
	@extend %icon;

	&:before {
		content: "\eb43";
	}
}

a.comment-action.icon-close {
	@extend %icon;

	&:before {
		content: "\e980" !important;
		font-size: 0.9em;
	}
}

.bullet:before {
	@extend %icon;

	content: '\25d8';
	vertical-align: super;
}