.ls-blur-up-img,
.optimized-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;

	/* only if you want to change the blur-up option from auto to always */
	font-family: "blur-up: always", "object-fit: cover";

	object-fit: cover;
}

.ls-blur-up-img {
	filter: blur(10px);
	opacity: 1;
	transition: opacity 1000ms, filter 1500ms;
}

.ls-blur-up-img.ls-inview.ls-original-loaded {
	opacity: 0;
	filter: blur(5px);
}