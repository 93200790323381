%bullets {
	& > li {
		@apply w-full flex px-6;

		.checkmark {
			@apply rounded-full flex-no-shrink text-center flex items-center justify-center;
		}

		& > a {
			@apply flex;
		}
	}
}

.bullets-small {
	@extend %bullets;

	& > li {
		@apply py-1;

		.checkmark {
			@apply w-6 h-6 mr-2 text-base mt-1;
		}

		.bullet-text {
			@apply leading-loose font-medium;
		}
	}
}

.bullets-large {
	@extend %bullets;

	& > li {
		@apply py-4;

		@screen xl {
			@apply w-1/2 py-8;
		}

		.checkmark {
			@apply w-12 h-12 mr-4 leading-loose text-xl font-bold mt-1;

			@apply md {
				@apply w-16 h-16 text-2xl -mt-1;
			}
		}

		.bullet-text {
			@apply text-2xl leading-loose;
		}
	}
}


.bullets-grey {
	.checkmark {
		@apply border-2 border-grey text-grey-lightest;
	}
}

.bullets-black {
	.checkmark {
		@apply border border-black text-black;
	}

	& a:hover .checkmark {
		@apply border border-orange text-orange;
	}
}

.bullets-orange {
	.checkmark {
		@apply border-none bg-orange text-grey-lightest;
	}
}