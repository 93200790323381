.navbars {
	@apply fixed w-full z-10;
}

.navbar {
	@apply w-full p-12 flex flex-wrap justify-between items-center relative;

	@apply transition-padding-and-bg transition-duration-200;
}

.navbar-scrolled {
	@apply bg-blue-darkest py-4;

	@apply transition-padding-and-bg transition-duration-200;
}

#navbar.opened:not(.navbar-scrolled) > .background {
	@apply opacity-50;
}

.nav-item {
	@apply text-sm font-bold text-grey-lighter tracking-wide no-underline transition-text transition-duration-200;

	&.active {
		@apply text-orange border-b-2 border-orange;
	}

	&:hover {
		@apply text-orange;
	}

	@screen lg {
		@apply tracking-wider;
	}
	@screen xl {
		@apply text-base;
	}
}

.navbar-toggle {
	@apply flex items-center px-3 py-2 border-3 border-gradient-br-orange text-white;

	&:focus {
		@apply border-gradient-br-white outline-none;
	}
}

.mobile-navbar {
	@apply w-full transition-opacity-and-max-height transition-duration-500 relative overflow-hidden;

	&.opened {
		@apply opacity-100 max-h-screen;
	}

	& #mobile-navbar-button > a {
		@apply my-0 btn-blue-outline w-full text-center;
		border-image: none;
	}
}

/* Animate menu icon (lines). */
.navbar-toggle-group .line {
	opacity: 1;
	transform: rotate(0) translateY(0) translateX(0);
	transform-origin: 1em 1em;
	transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
}
.navbar-toggle-group .line-1 {
	transform-origin: 1em 2.5em;
}
.navbar-toggle-group .line-3 {
	transform-origin: 1em 4.5em;
}

#navbar.opened .navbar-toggle-group .line-1 {
	transform: rotate(45deg) translateY(0) translateX(0);
}
#navbar.opened .navbar-toggle-group .line-2 {
	opacity: 0;
}
#navbar.opened .navbar-toggle-group .line-3 {
	transform: rotate(-45deg) translateY(0em) translateX(0em);
}