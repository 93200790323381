.contactForm:not(:first-child) {
	@apply mt-8;
}

.form-items {
	[data-role="reply"] & {
		@apply pt-6;
	}
}

.form-item {

	textarea {
		@apply resize-none min-h-50;
	}

	%input {
		@apply relative w-full;

		& > input,
		& > textarea {
			@apply border-2 border-grey p-4 w-full outline-none text-grey-darkest leading-normal;

			&:focus {
				@apply border-orange-lighter;
			}
		}

		& > label {
			@apply absolute pin-t pin-x font-bold leading-none border-2 border-transparent py-4 px-4 w-full bg-white leading-normal;
			background-clip: padding-box;
		}
	}

	@responsive {
		.input-horizontal {
			@extend %input;

			& > input,
			& > textarea {
				@apply pl-24 pt-4;
			}

			& > label {
				@apply w-24;
			}
		}

		.input-horizontal-lg {
			@extend %input;

			& > input,
			& > textarea {
				@apply pl-40 pt-4;
			}

			& > label {
				@apply w-40;
			}
		}

		.input-vertical {
			@extend %input;

			& > input,
			& > textarea {
				@apply pl-4 pt-16;
			}

			& > label {
				@apply w-full;
			}
		}

		.input-normal {
			@extend %input;

			& > input,
			& > textarea {
				@apply w-full;
			}

			& > label {
				@apply w-full inline-block relative pl-0 pt-0 pr-2;
			}
		}

		.input-search {
			@extend %input;

			& > button {
				@apply absolute pin-y pin-r border-2 border-transparent py-3 px-4 bg-white;
				background-clip: padding-box;

				&:hover {
					@apply text-orange transition-text;
				}

				&:focus {
					@apply outline-none bg-orange text-white;
				}
			}
		}
	}
}

.select2 {
	@apply w-full #{!important};

	.select2-selection {
		@apply border-2 border-grey rounded-none h-auto outline-none #{!important};

		&:focus {
			@apply border-orange-lighter #{!important};
		}

		&__rendered {
			@apply p-4 #{!important};
		}
	}

	.select2-selection__arrow {
		@extend .icon-down;
		@apply p-6 w-16 h-16 bg-grey-lighter border-2 border-transparent pin-t pin-r #{!important};
		background-clip: padding-box;
		-webkit-text-stroke: 3px config('colors.grey-darker');

		b {
			@apply hidden #{!important};
		}
	}
}

.select2-dropdown {
	@apply border-b-2 border-r-2 border-l-2 border-grey rounded-none;

	.select2-results__option {
		@apply p-4 #{!important};

		&[aria-selected=true] {
			@apply bg-grey-light text-black #{!important};

			&:hover {
				@apply text-black #{!important};
			}
		}

		&--highlighted {
			@apply bg-orange #{!important};
		}
	}
}

input.labelauty + label {
	@apply relative py-4 px-5 rounded-full bg-grey-lighter mb-3 #{!important};

	@apply w-5/6  #{!important};
	@screen sm {
		@apply w-auto mr-5 #{!important};
	}

	.labelauty-unchecked,
	.labelauty-checked {
		@apply text-base font-normal #{!important};
	}

	.labelauty-unchecked-image {
		@apply hidden #{!important};
		background-image: none !important;
	}

	.labelauty-unchecked {
		@apply text-orange #{!important};
	}

	.labelauty-checked-image {
		@extend .icon-checkmark;
		background-image: none !important;

		@apply absolute pin-y my-auto bg-white text-grey-darker rounded-full w-5 h-5 p-1 text-2xs border border-orange #{!important};
		right: -0.5rem;
		-webkit-text-stroke: 2px config('colors.grey-darker');
	}
}

input.labelauty:checked + label {
	@apply bg-orange #{!important};
}

.newsletterSignupForm input {
	@apply border-gradient-blue-light border-3 bg-transparent text-white #{!important};

	&::placeholder {
		@apply opacity-75;
	}
}


.form-item .has-error > input,
.form-item .has-error > textarea,
.form-item .has-error .select2-selection {
	@apply border-red;
}
