%block {
	@apply py-2 px-3 m-1;

	@screen md {
		@apply py-4 px-5;
	}
}

.filter {
	@extend %block;

	@apply font-bold;
}

.paginate-link {
	@extend %block;
}

.filter.selected,
.paginate-link.selected {
	@apply bg-orange text-white;
}

.filter.not-selected,
.paginate-link.not-selected  {
	@apply bg-grey-lighter text-orange no-underline;
}

