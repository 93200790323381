%btn {
	@apply inline py-4 px-8 font-medium tracking-widest uppercase leading-normal;

	background-origin: border-box;
}

%btn-underline {
	@apply inline pt-1 pb-1 font-medium text-lg border-b;
}

@variants hover {

	.btn-orange-gradient-outline {
		@extend %btn;

		@apply bg-transparent border-3 border-gradient-br-orange text-grey-lighter;
		@apply py-3 px-4 tracking-wide;
	}

	.btn-blue-outline {
		@extend %btn;

		@apply bg-transparent border-3 border-gradient-blue text-grey-lighter;
	}

	.btn-grey-outline {
		@extend %btn;

		@apply bg-transparent border-2 border-grey text-grey-darker;
	}

	.btn-white {
		@extend %btn;

		@apply bg-white border-3 border-transparent border-gradient-t-transparent text-blue;
	}

	.btn-orange {
		@extend %btn;

		@apply bg-orange border-2 border-transparent border-gradient-t-transparent text-grey-lightest;
	}

	.btn-grey {
		@extend %btn;

		@apply bg-grey-darker border-2 border-transparent border-gradient-t-transparent text-grey-lightest;
	}

	.btn-blue-gradient {
		@extend %btn;

		@apply bg-gradient-330-blue border-3 border-transparent border-gradient-t-transparent text-grey-lightest;
	}

	.btn-blue-underline {
		@extend %btn-underline;

		@apply text-blue border-blue;
	}

	.btn-white-underline {
		@extend %btn-underline;

		@apply text-grey-lightest border-grey-lightest;
	}

	.btn-orange-underline {
		@extend %btn-underline;

		@apply text-orange border-orange;
	}

}

%social-btn {
	@apply inline-block mr-4 w-16 h-16 border-2 rounded-full;

	&:last-child {
		@apply mr-0;
	}

	& > a {
		@apply h-full w-full flex justify-center items-center;

		& > svg {
			@apply fill-current w-8 h-8;
		}
	}

	.blogPostSection & {
		@apply w-12 h-12;

		& > a  > svg {
			@apply w-5 h-5;
		}
	}

	footer & {
		@apply w-12 h-12;

		@apply opacity-50 border-0 bg-blue-darkest;

		& > a {
			@apply text-white;

			& > svg {
				@apply w-5 h-5;
			}
		}

		&:hover {
			@apply bg-white;

			& > a {
				@apply text-blue-darker;
			}
		}
	}
}

.btn-facebook {
	@extend %social-btn;

	@apply border-facebook;

	& > a {
		@apply text-facebook;
	}

	&:hover {
		@apply bg-facebook;

		& > a {
			@apply text-white;
		}
	}
}

.btn-twitter {
	@extend %social-btn;

	@apply border-twitter;

	& > a {
		@apply text-twitter;
	}

	&:hover {
		@apply bg-twitter;

		& > a {
			@apply text-white;
		}
	}
}

.btn-linkedin {
	@extend %social-btn;

	@apply border-linkedin;

	& > a {
		@apply text-linkedin;
	}

	&:hover {
		@apply bg-linkedin;

		& > a {
			@apply text-white;
		}
	}
}

.btn-dribbble {
	@extend %social-btn;

	@apply border-dribbble;

	& > a {
		@apply text-dribbble;
	}

	&:hover {
		@apply bg-dribbble;

		& > a {
			@apply text-white;
		}
	}
}

.btn-youtube {
	@extend %social-btn;

	@apply border-youtube;

	& > a {
		@apply text-youtube;
	}

	&:hover {
		@apply bg-youtube;

		& > a {
			@apply text-white;
		}
	}
}