.slick-dots {
	@apply absolute pin-b pin-x mx-auto list-reset flex justify-center mb-16;

	@screen max-sm {
		margin-bottom: 21rem;
	}

	& > li {
		font-size: 0;
		line-height: 0;

		@apply border-2 border-white w-4 h-4 rounded-full mx-1;

		&.slick-active {
			@apply bg-orange border-transparent;
		}
	}
}

@screen md {
	.slick-track {
		@apply flex;

		.testimonial {
			@apply flex w-full h-auto justify-center items-center;
		}
	}
}