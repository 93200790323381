$corners: (
	(t, r, white, 0 100px 100px 0, 0 200px 200px 0, transparent white transparent transparent),
	(b, r, white, 0 0 100px 100px, 0 0 200px 200px, transparent transparent white transparent),
	(b, l, white, 100px 0 0 100px, 200px 0 0 200px, transparent transparent transparent white),
	(t, l, white, 100px 100px 0 0, 200px 200px 0 0, white transparent transparent transparent),
	(t, r, darkBlue, 0 100px 100px 0, 0 200px 200px 0, transparent #163348 transparent transparent),
	(b, r, darkBlue, 0 0 100px 100px, 0 0 200px 200px, transparent transparent #163348 transparent),
	(b, l, darkBlue, 100px 0 0 100px, 200px 0 0 200px, transparent transparent transparent #163348),
	(t, l, darkBlue, 100px 100px 0 0, 200px 200px 0 0, #163348 transparent transparent transparent),
	(t, r, blue, 0 100px 100px 0, 0 200px 200px 0, transparent #218aae transparent transparent),
	(b, r, blue, 0 0 100px 100px, 0 0 200px 200px, transparent transparent #218aae transparent),
	(b, l, blue, 100px 0 0 100px, 200px 0 0 200px, transparent transparent transparent #218aae),
	(t, l, blue, 100px 100px 0 0, 200px 200px 0 0, #218aae transparent transparent transparent)
);

@each $dir1, $dir2, $color, $border-width-sm, $border-width-lg, $border-color in $corners {
	.triangle-#{$dir1}#{$dir2}-#{$color} {
		@apply absolute w-0 h-0 border-solid;

		@apply pin-#{$dir1} pin-#{$dir2};

		border-width: $border-width-sm;
		border-color: $border-color;

		@screen md {
			border-width: $border-width-lg;
		}
	}
}

.concave-pentagon {
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 65%, 0% 100%);
}