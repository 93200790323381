body {
	@apply font-sans text-black;
}

%heading {
	@apply block;

	& > em {
		@apply roman;
	}
}


.faux-h1 {
	@extend %heading;

	@apply uppercase text-5xl font-bold;

	@screen md {
		@apply text-6xl;
	}
}

.faux-h1-alt {
	@extend %heading;

	@apply uppercase text-4xl font-normal leading-none pt-1 pb-4 font-sans-condensed;

	@screen md {
		@apply text-5xl;
	}

	&.border-skewed-orange {
		@apply max-w-sm;

		&.text-center {
			@apply mx-auto;
		}
	}

	&.md\:text-xxl {
		@apply max-w-xl;

		&.text-center {
			@apply mx-auto;
		}
	}
}

.faux-h2 {
	@extend %heading;

	@apply normal-case text-4xl font-normal leading-tight pt-1 pb-4;

	@screen md {
		@apply text-5xl;
	}
}

.faux-h2-alt {
	@extend .faux-h2;

	@apply font-bold;
}

.faux-h3 {
	@extend %heading;

	@apply normal-case text-3xl font-normal leading-normal pt-1 pb-2;

	@screen md {
		@apply text-4xl;
	}
}

.faux-h3-alt {
	@extend .faux-h3;

	@apply uppercase tracking-wider;
}

.faux-h4 {
	@extend %heading;

	@apply normal-case text-xl font-normal leading-normal pt-1 pb-2;

	@screen md {
		@apply text-2xl;
	}
}

.faux-h4-alt {
	@extend .faux-h4;

	@apply uppercase tracking-wider;
}

.faux-h5 {
	@extend %heading;

	@apply uppercase text-xl font-medium tracking-wide;
}

.faux-h5-alt {
	@extend .faux-h5;

	@apply font-bold;
}

.faux-h6 {
	@extend %heading;

	@apply uppercase text-base font-medium tracking-wide;
}

.faux-h6-alt {
	@extend .faux-h6;

	@apply font-bold;
}

.standard-p {
	@apply text-base leading-loose my-4;

	&:first-child {
		@apply mt-0;
	}

	.blogPostSection .section-content .richText:first-child > & {
		@apply text-lg;
	}

	.blogPostSection .section-content & {
		@apply leading-relaxed;
	}

	//@screen md {
	//	@apply text-lg;
	//
	//	.blogPostSection .section-content &:not(:first-child) {
	//		@apply text-base;
	//	}
	//}

	& a {
		@apply text-orange;
	}
}

.heroSection .standard-p {
	@apply max-w-md;
}

small {
	@apply text-lg;
}