%border-skewed {
	@apply relative pb-6 leading-none;

	&:after {
		@apply absolute w-16 pin-b pin-l;
		content: "";
		height: 5px;
		transform: skewX(45deg);
	}

	&.text-center:after {
		@apply pin-x mx-auto;
	}
}

.border-skewed-grey {
	@extend %border-skewed;

	&:after {
		@apply bg-grey;
	}
}

.border-skewed-orange {
	@extend %border-skewed;

	&:after {
		@apply bg-orange;
	}

	&.text-xxl {
		&:after {
			@apply w-24 h-2;
		}
	}
}