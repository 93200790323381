.row {
	@apply flex flex-wrap justify-center relative;
}

$sm-screen: 576;
$md-screen: 768;
$lg-screen: 992;
$xl-screen: 1200;
$xxl-screen: 1600;

.container {
	@apply w-full mx-auto;

	@screen sm { max-width: $sm-screen + px; }
	@screen md { max-width: $md-screen + px; }
	@screen lg { max-width: $lg-screen + px; }
	@screen xl { max-width: $xl-screen + px; }
}

.container-wide {
	@extend .container;
	@screen sm { @apply max-w-full; }
	@screen xxl { max-width: $xxl-screen + px; }
}

.section {
	@apply py-20 px-8 relative;

	@screen lg {
		@apply px-32;
	}
}

.section-content {
	@apply min-w-full flex flex-wrap;
}

.section-content > .heading,
.section-content > .richText,
.section-content > .button,
.section-content > .bulletPoints {
	@apply px-8;
}

.imageSection {
	@apply min-h-100 px-0;

	@screen max-sm {
		@apply -mt-20 mb-20 py-12;

		.subSection ~ & {
			@apply -mb-20 mt-20;
		}

		.triangle-tr-white {
			border-width: 0 0 100px 100px;
			border-color: transparent transparent white transparent;
			top: auto !important;
			bottom: 0;
		}
	}

	@screen md {
		@apply pin-l my-0;

		.subSection ~ & {
			@apply pin-r my-0;
			left: auto;
		}
	}
}

.subSection + .subSection {
	@screen max-sm {
		@apply pt-12;
	}
}

.serviceOrder {
	& div:nth-child(1) {
		order: 1;
	}
	& div:nth-child(2) {
		order: 2;
	}
	& div:nth-child(3) {
		order: 3;

		@screen md {
			order: 4;
		}
	}
	& div:nth-child(4) {
		order: 4;

		@screen md {
			order: 3;
		}
	}
	& div:nth-child(5) {
		order: 5;
	}
	& div:nth-child(6) {
		order: 6;
	}

	.triangle-tl-darkBlue,
	.triangle-tr-darkBlue {
		@apply hidden;

		@screen md {
			@apply block;
		}
	}

	div:last-child,
	div:nth-last-child(2) {
		& .triangle-bl-darkBlue,
		& .triangle-br-darkBlue {
			@apply block;

			@screen md {
				@apply hidden;
			}
		}
	}
}

.testimonialSection > .absolute[class*="bg-"]:nth-child(2) {
	width: (100% / 3);
	left: auto;

	@screen max-sm {
		@apply h-80;
		top: auto;
	}
}